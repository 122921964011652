import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authReducer from './Redux/Reducers/auth';
import categoriesReducer from './Redux/Reducers/categories';
import marketplacesReducer from './Redux/Reducers/marketplaces';
import shipmentCenterReducer from './Redux/Reducers/shipmentcenter';
import productsReducer from './Redux/Reducers/products';
import brandsReducers from './Redux/Reducers/brands';
import descriptionModelsReducers from './Redux/Reducers/descriptionModels';
import skusReducer from './Redux/Reducers/skus';
import modalReducer from './Redux/Reducers/modals';
import suppliersReducer from './Redux/Reducers/suppliers';
import systemStatus from './Redux/Reducers/systemStatus';
import shelvingsReducer from './Redux/Reducers/shelvings';
import locationsReducer from './Redux/Reducers/locations';
import supplierOrdersReducer from './Redux/Reducers/supplierOrders';
import productMovementsReducer from './Redux/Reducers/productMovements';
import attributesReducer from './Redux/Reducers/attributes';
import preordersReducer from './Redux/Reducers/preorders';
import messageReducer from './Redux/Reducers/messages';
import customersReducer from './Redux/Reducers/customers';
import systemAlertsReducer from './Redux/Reducers/systemAlerts';
import ordersReducer from './Redux/Reducers/orders';
import cartsReducer from './Redux/Reducers/carts';
import missionsReducer from './Redux/Reducers/missions';
import configReducer from './Redux/Reducers/config';
import boxesReducer from './Redux/Reducers/boxes';
import thunk from 'redux-thunk';
import apiMiddleware from './middleware/api';
import skuOwnersReducer from './Redux/Reducers/skuOwners';
import sysPanelReducer from './Redux/Reducers/sysPanel';
import checkInventoriesReducer from './Redux/Reducers/checkInventories';
import coreTableReducer from './Redux/Reducers/coreTable';

import collections from './Redux/collections/reducer';
import simpleValues from './Redux/simpleValues/reducer';
import {Authenticator} from '@aws-amplify/ui-react';

const appReducer = combineReducers({
  config: configReducer,
  customers: customersReducer,
  missions: missionsReducer,
  shipmentcenter: shipmentCenterReducer,
  shelvings: shelvingsReducer,
  movements: productMovementsReducer,
  marketplaces: marketplacesReducer,
  carts: cartsReducer,
  locations: locationsReducer,
  attributes: attributesReducer,
  suppliers: suppliersReducer,
  auth: authReducer,
  message: messageReducer,
  alerts: systemAlertsReducer,
  categories: categoriesReducer,
  modal: modalReducer,
  products: productsReducer,
  skus: skusReducer,
  supplierOrders: supplierOrdersReducer,
  orders: ordersReducer,
  brands: brandsReducers,
  descriptionModels: descriptionModelsReducers,
  skuOwners: skuOwnersReducer,
  sysPanel: sysPanelReducer,
  checkInventories: checkInventoriesReducer,
  systemStatus: systemStatus,
  preorders: preordersReducer,
  // custom from gestionale_carte
  simpleValues: simpleValues,
  collections: collections,
  boxes: boxesReducer,
  //* CoreTable
  coreTable: coreTableReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
};

const middlewares = [thunk, apiMiddleware];

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(persistedReducer, enhancer);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
