export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SET_APP_STATISTICS = 'SET_APP_STATISTICS';

export const SET_SYS_PANEL = 'SET_SYS_PANEL';
export const SET_SYS_PANEL_LOG_DATA = 'SET_SYS_PANEL_LOG_DATA';
export const SET_SYS_PANEL_GENERIC_DATA = 'SET_SYS_PANEL_GENERIC_DATA';
export const SET_SYS_PANEL_FILTER_DATE = 'SET_SYS_PANEL_FILTER_DATE';
export const SET_SYS_PANEL_LABELS = 'SET_SYS_PANEL_LABELS';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
export const RESET_CURRENT_POSITION = 'RESET_CURRENT_POSITION';
export const FORCE_REDIRECT = 'FORCE_REDIRECT';
export const RESET_REDIRECT = 'RESET_REDIRECT';
export const SET_TABLE_PARAMETERS = 'SET_TABLE_PARAMETERS';
export const RESET_TABLE_PARAMETERS = 'RESET_TABLE_PARAMETERS';
export const CHANGE_TABLE_PARAMETERS = 'CHANGE_TABLE_PARAMETERS';

export const SET_SELECTED_SKU_OWNER = 'SET_SELECTED_SKU_OWNER';
export const SET_SKU_OWNERS_LIST = 'SET_SKU_OWNERS_LIST';

export const SET_BRANDS_LIST = 'SET_BRANDS_LIST';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const EDIT_SELECTED_BRAND_VALUE = 'EDIT_SELECTED_BRAND_VALUE';

export const SET_DESCRIPTION_MODELS = 'SET_DESCRIPTION_MODELS';
export const SET_SELECTED_DESCRIPTION_MODEL = 'SET_SELECTED_DESCRIPTION_MODEL';
export const EDIT_SELECTED_DESCRIPTION_MODEL_VALUE =
  'EDIT_SELECTED_DESCRIPTION_MODEL_VALUE';

export const SET_NEAREST_ITEM_TO_CHECK = 'SET_NEAREST_ITEM_TO_CHECK';
export const RESET_ITEM_TO_CHECK = 'RESET_ITEM_TO_CHECK';
export const SET_ITEM_TO_PICK = 'SET_ITEM_TO_PICK';
export const RESET_ITEM_TO_PICK = 'RESET_ITEM_TO_PICK';

export const SET_MISSION_DATA = 'SET_MISSION_DATA';
export const RESET_CREATE_MISSION = 'RESET_CREATE_MISSION';
export const SET_MISSION_TYPE = 'SET_MISSION_TYPE';
export const SET_MISSION_PROPERTY = 'SET_MISSION_PROPERTY';
export const SET_MISSION_LIST = 'SET_MISSION_LIST';
export const SET_CHILD_MISSION_LIST = 'SET_CHILD_MISSION_LIST';
export const RESET_MISSION_LIST = 'RESET_MISSION_LIST';

export const TOGGLE_BARCODE_READER = 'TOGGLE_BARCODE_READER';
export const TOGGLE_QRCODE_READER = 'TOGGLE_QRCODE_READER';

export const SET_CUSTOMER_SEARCH = 'SET_CUSTOMER_SEARCH';

export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CHANGE_DIALOG_PAYLOAD_VALUE = 'CHANGE_DIALOG_PAYLOAD_VALUE';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_PICTURE = 'SET_PICTURE';
export const SET_PROFILE_INFO = 'SET_PROFILE_INFO';

export const SET_ATTRIBUTES_LIST = 'SET_ATTRIBUTES_LIST';
export const SET_SELECTED_ATTRIBUTE = 'SET_SELECTED_ATTRIBUTE';

export const SET_THREAD_LIST = 'SET_THREAD_LIST';
export const SET_MESSAGE_LIST = 'SET_MESSAGE_LIST';
export const SET_UNREAD_MESSAGE = 'SET_UNREAD_MESSAGE';
export const SET_AVAILABLE_RECIPIENTS = 'SET_AVAILABLE_RECIPIENTS';
export const SET_SELECTED_RECIPIENTS = 'SET_SELECTED_RECIPIENTS';
export const REMOVE_SELECTED_RECIPIENT = 'REMOVE_SELECTED_RECIPIENT';
export const RESET_RECIPIENT = 'RESET_RECIPIENT';

export const SET_SHIPMENTCENTER_ORDERS = 'SET_SHIPMENTCENTER_ORDERS';
export const SHIPMENTCENTER_SET_CURRENT_POSITION =
  'SHIPMENTCENTER_SET_CURRENT_POSITION';
export const SET_SYSTEM_STATUS = 'SET_SYSTEM_STATUS';
export const SET_SHIPMENTCENTER_IDPANEL = 'SET_SHIPMENTCENTER_IDPANEL';
export const SET_SHIPMENTCENTER_IDSTATE = 'SET_SHIPMENTCENTER_IDSTATE';
export const SET_SHIPMENTCENTER_CARTS = 'SET_SHIPMENTCENTER_CARTS';
export const SET_SHIPMENTCENTER_SELECTEDCART =
  'SET_SHIPMENTCENTER_SELECTEDCART';
export const SET_SHIPMENTCENTER_PICKING_BATCHES =
  'SET_SHIPMENTCENTER_PICKING_BATCHES';
export const SET_PICKING_BATCHES_FOR_CARTS = 'SET_PICKING_BATCHES_FOR_CARTS';
export const SET_SHIPMENTCENTER_START_COLLECTING =
  'SET_SHIPMENTCENTER_START_COLLECTING';
export const SET_SHIPMENTCENTER_SHELVINGS = 'SET_SHIPMENTCENTER_SHELVINGS';
export const SET_COLLECTPROCESS_NEXTPRODUCTS =
  'SET_COLLECTPROCESS_NEXTPRODUCTS';
export const SET_COLLECTPROCESS_IDCART = 'SET_COLLECTPROCESS_IDCART';
export const RESET_SHIPMENTCENTER_CART = 'RESET_SHIPMENTCENTER_CART';
export const SET_SHIPMENTCENTER_ISCLOSING = 'SET_SHIPMENTCENTER_ISCLOSING';
export const SET_SHIPMENTCENTER_CLOSINGCART = 'SET_SHIPMENTCENTER_CLOSINGCART';
export const SET_SHIPMENTCENTER_CLOSINGORDER =
  'SET_SHIPMENTCENTER_CLOSINGORDER';
export const SHIPMENTCENTER_PUT_IN_PARCEL = 'SHIPMENTCENTER_PUT_IN_PARCEL';
export const SHIPMENTCENTER_PUT_ALL_IN_PARCEL =
  'SHIPMENTCENTER_PUT_ALL_IN_PARCEL';
export const RESET_SHIPMENTCENTER_CLOSINGORDER =
  'RESET_SHIPMENTCENTER_CLOSINGORDER';
export const SET_SHIPMENTCENTER_SELECTEDBOX = 'SET_SHIPMENTCENTER_SELECTEDBOX';
export const SET_NEED_QUIT_FROM_CLOSING = 'SET_NEED_QUIT_FROM_CLOSING';
export const SHIPMENTCENTER_EMPTY_PICK_PRODUCT_ROW =
  'SHIPMENTCENTER_EMPTY_PICK_PRODUCT_ROW';
export const SET_SHIPMENTCENTER_WANTQUIT = 'SET_SHIPMENTCENTER_WANTQUIT';
export const SET_SHIPMENTCENTER_CREATED_LABEL =
  'SET_SHIPMENTCENTER_CREATED_LABEL';

export const SET_BOXES_LIST = 'SET_BOXES_LIST';

export const SET_SKUS_LIST = 'SET_SKUS_LIST';
export const SET_SKUS_STATS = 'SET_SKUS_STATS';
export const RESET_SKUS_STATS = 'RESET_SKUS_STATS';
export const EDIT_SYNC_SKU_ATTRIBUTE = 'EDIT_SYNC_SKU_ATTRIBUTE';
export const EDIT_SYNC_MARKETPLACE_VALUE = 'EDIT_SYNC_MARKETPLACE_VALUE';
export const SET_COMBINATION_IMAGE = 'SET_COMBINATION_IMAGE';
export const SET_SELECTED_SKU = 'SET_SELECTED_SKU';
export const RESET_SELECTED_SKU = 'RESET_SELECTED_SKU';
export const EDIT_SELECTED_SKU_ATTRIBUTE = 'EDIT_SELECTED_SKU_ATTRIBUTE';
export const EDIT_SELECTED_SKU_TAG = 'EDIT_SELECTED_SKU_TAG';
export const EDIT_SELECTED_Sku_Combination_Attribute =
  'EDIT_SELECTED_Sku_Combination_Attribute';
export const EDIT_MULTIPLE_SELECTED_SKU = 'EDIT_MULTIPLE_SELECTED_SKU';
export const CHANGE_SELECTED_SKU_PANEL = 'CHANGE_SELECTED_SKU_PANEL';
export const SET_SKU_SEARCH_PRODUCTS_RESULT = 'SET_SKU_SEARCH_PRODUCTS_RESULT';
export const SET_SKU_SEARCH_RESULT = 'SET_SKU_SEARCH_RESULT';
export const RESET_SEARCH_SKU = 'RESET_SEARCH_SKU';
export const SET_SKUS_RELOCATE_LIST = 'SET_SKUS_RELOCATE_LIST';
export const SET_RELOCATE_RESERVATION = 'SET_RELOCATE_RESERVATION';
export const RESET_RELOCATE_RESERVATION = 'RESET_RELOCATE_RESERVATION';
export const SET_RELOCATION_ROWS = 'SET_RELOCATION_ROWS';
export const SET_NEED_RELOCATION = 'SET_NEED_RELOCATION';
export const SET_RELOCATION_SELECTED_SHELF = 'SET_RELOCATION_SELECTED_SHELF';
export const SET_RELOCATION_SELECTED_CART = 'SET_RELOCATION_SELECTED_CART';
export const CHANGE_RELOCATION_ROW_VALUE = 'CHANGE_RELOCATION_ROW_VALUE';
export const SET_INVENTORY_CONTROL_ROWS = 'SET_INVENTORY_CONTROL_ROWS';
export const SET_INVENTORY_REMOVAL_ROWS = 'SET_INVENTORY_REMOVAL_ROWS';
export const SET_INVENTORY_REMOVAL_WITH_INFO_ROWS =
  'SET_INVENTORY_REMOVAL_WITH_INFO_ROWS';
export const SET_SYNC_MARKETPLACE = 'SET_SYNC_MARKETPLACE';

export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';

export const SET_WAREHOUSE_PANEL = 'SET_WAREHOUSE_PANEL';
export const SET_WAREHOUSE_PANELS_LIST = 'SET_WAREHOUSE_PANELS_LIST';

export const SET_MARKETPLACES_LIST = 'SET_MARKETPLACES_LIST';
export const SET_ALL_MARKETPLACES = 'SET_ALL_MARKETPLACES';
export const RESET_ALL_MARKETPLACES = 'RESET_ALL_MARKETPLACES';
export const SET_SELECTED_PRODUCT_ATTRIBUTE_MAP =
  'SET_SELECTED_PRODUCT_ATTRIBUTE_MAP';
export const EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE =
  'EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE';
export const RESET_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE =
  'RESET_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE';
export const EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_MAP =
  'EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_MAP';
export const SET_SELECTED_MARKETPLACE = 'SET_SELECTED_MARKETPLACE';
export const RESET_SELECTED_MARKETPLACE = 'RESET_SELECTED_MARKETPLACE';
export const EDIT_SELECTED_MARKETPLACE_VALUE =
  'EDIT_SELECTED_MARKETPLACE_VALUE';
export const CHANGE_MARKETPLACE_PANEL = 'CHANGE_MARKETPLACE_PANEL';
export const TOGGLE_MARKETPLACE_NEED_SAVE = 'TOGGLE_MARKETPLACE_NEED_SAVE';

export const SET_VARIANTS_LIST = 'SET_VARIANTS_LIST';
export const SET_SELECTED_VARIANT = 'SET_SELECTED_VARIANT';

export const SET_SUPPLIERS_LIST = 'SET_SUPPLIERS_LIST';
export const SET_SELECTED_SUPPLIER = 'SET_SELECTED_SUPPLIER';
export const EDIT_SELECTED_SUPPLIER = 'EDIT_SELECTED_SUPPLIER';

export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const SET_BASE_PRODUCTS_LIST = 'SET_BASE_PRODUCTS_LIST';
export const SET_BASE_PRODUCTS_CATEGORIES_LIST =
  'SET_BASE_PRODUCTS_CATEGORIES_LIST';
export const SET_CUSTOM_CREATIONS_LIST = 'SET_CUSTOM_CREATIONS_LIST';
export const SET_COMBINATIONS_SELECTED = 'SET_COMBINATIONS_SELECTED';
export const SET_CUSTOM_CREATIONS_TO_APPROVE_LIST =
  'SET_CUSTOM_CREATIONS_TO_APPROVE_LIST';
export const SET_CUSTOM_CREATIONS_TO_PRINT_LIST =
  'SET_CUSTOM_CREATIONS_TO_PRINT_LIST';
export const SET_CUSTOM_CREATIONS_TO_PRINT_FOR_PRODUCT_LIST =
  'SET_CUSTOM_CREATIONS_TO_PRINT_FOR_PRODUCT_LIST';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const RESET_SELECTED_PRODUCT = 'RESET_SELECTED_PRODUCT';
export const EDIT_SELECTED_PRODUCT = 'EDIT_SELECTED_PRODUCT';
export const RAISE_CHANGED_ATTRIBUTE_EVENT = 'RAISE_CHANGED_ATTRIBUTE_EVENT';
export const EDIT_MULTIPLE_SELECTED_PRODUCT = 'EDIT_MULTIPLE_SELECTED_PRODUCT';
export const CHANGE_SELECTED_PRODUCT_PANEL = 'CHANGE_SELECTED_PRODUCT_PANEL';
export const CHANGE_SELECTED_PRODUCT_IMAGE = 'CHANGE_SELECTED_PRODUCT_IMAGE';
export const CHANGE_SELECTED_PRODUCT_DATA = 'CHANGE_SELECTED_PRODUCT_DATA';
export const SET_CREATED_PRODUCT_ID = 'SET_CREATED_PRODUCT_ID';
export const RESET_CREATED_PRODUCT_ID = 'RESET_CREATED_PRODUCT_ID';

export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const SET_UNIMPORTED_ORDERS_LIST = 'SET_UNIMPORTED_ORDERS_LIST';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const SET_ORDER_RESOURCES_PRODUCTS = 'SET_ORDER_RESOURCES_PRODUCTS';
export const SET_ORDER_RESOURCES_ADDRESSES = 'SET_ORDER_RESOURCES_ADDRESSES';
export const SET_ORDER_RESOURCES_CUSTOMERS = 'SET_ORDER_RESOURCES_CUSTOMERS';
export const SET_ORDER_RESOURCES_START = 'SET_ORDER_RESOURCES_START';
export const SET_ORDER_PROPERTY_CUSTOMER = 'SET_ORDER_PROPERTY_CUSTOMER';
export const SET_ORDER_PROPERTY_PRODUCT = 'SET_ORDER_PROPERTY_PRODUCT';
export const SET_ORDER_RESOURCE_COUNTRY = 'SET_ORDER_RESOURCE_COUNTRY';
export const SET_ORDER_RESOURCE_PROVINCE = 'SET_ORDER_RESOURCE_PROVINCE';
export const SET_ORDER_PROPERTY_PRODUCTS = 'SET_ORDER_PROPERTY_PRODUCTS';
export const SET_ORDER_PROPERTY_ADDRESS = 'SET_ORDER_PROPERTY_ADDRESS';
export const SET_ORDER_PROPERTY_OWNER = 'SET_ORDER_PROPERTY_OWNER';
export const SET_ORDER_PROPERTY_PRODUCT_COMBINATION =
  'SET_ORDER_PROPERTY_PRODUCT_COMBINATION';
export const SET_ORDER_PROPERTY_CARRIER = 'SET_ORDER_PROPERTY_CARRIER';
export const SET_ORDER_PROPERTY_CURRENCY = 'SET_ORDER_PROPERTY_CURRENCY';
export const SET_ORDER_PROPERTY_PAYMENT = 'SET_ORDER_PROPERTY_PAYMENT';
export const SET_ORDER_PROPERTY_STATE = 'SET_ORDER_PROPERTY_STATE';
export const SET_ORDER_NEXT_STEP = 'SET_ORDER_NEXT_STEP';
export const SET_ORDER_PROPERTY_COUPON = 'SET_ORDER_PROPERTY_COUPON';
export const SET_ORDER_PROPERTY_TOTALSHIPPING =
  'SET_ORDER_PROPERTY_TOTALSHIPPING';
export const SET_ORDER_PROPERTY_TOTALWRAPPING =
  'SET_ORDER_PROPERTY_TOTALWRAPPING';
export const SET_ORDER_PROPERTY_TOTALDISCOUNTS =
  'SET_ORDER_PROPERTY_TOTALDISCOUNTS';
export const RESET_CREATE_ORDER_STATE = 'RESET_CREATE_ORDER_STATE';
export const SET_SHIPPING_NUMBER = 'SET_SHIPPING_NUMBER';
export const SET_NEW_ORDER_CREATED = 'SET_NEW_ORDER_CREATED';
export const RESET_NEW_ORDER_CREATED = 'RESET_NEW_ORDER_CREATED';
//export const SET_TRACKING_AND_LABEL = 'SET_TRACKING_AND_LABEL';
//export const RESET_TRACKING_AND_LABEL = 'RESET_TRACKING_AND_LABEL';

export const SET_LOCATIONS_LIST = 'SET_LOCATIONS_LIST';
export const SET_SKU_LOCATIONS = 'SET_SKU_LOCATIONS';

export const SET_CARTS_LIST = 'SET_CARTS_LIST';
export const SET_SELECTED_CART = 'SET_SELECTED_CART';
export const EDIT_SELECTED_CART_VALUE = 'EDIT_SELECTED_CART_VALUE';

export const SET_PRODUCT_MOVEMENTS_LIST = 'SET_PRODUCT_MOVEMENTS_LIST';

export const REMOVE_SUPPLIER_ORDER_PROBLEM = 'REMOVE_SUPPLIER_ORDER_PROBLEM';
export const SET_SUPPLIER_ORDERS_LIST = 'SET_SUPPLIER_ORDERS_LIST';
export const SET_SELECTED_SUPPLIER_ORDER = 'SET_SELECTED_SUPPLIER_ORDER';
export const EDIT_SELECTED_SUPPLIER_ORDER = 'EDIT_SELECTED_SUPPLIER_ORDER';
export const SET_BARCODE_SEARCH_RESULT = 'SET_BARCODE_SEARCH_RESULT';
export const SET_NAME_SEARCH_RESULT = 'SET_NAME_SEARCH_RESULT';
export const UNSET_SELECTED_SUPPLIER_ORDER_PRODUCT =
  'UNSET_SELECTED_SUPPLIER_ORDER_PRODUCT';
export const PUSH_SELECTED_SUPPLIER_ORDER_PRODUCT =
  'PUSH_SELECTED_SUPPLIER_ORDER_PRODUCT';

export const SET_UNREAD_ALERTS = 'SET_UNREAD_ALERTS';
export const SET_ALERTS_LIST = 'SET_ALERTS_LIST';

export const SET_FRONTEND_CONFIG = 'SET_FRONTEND_CONFIG';

export const SET_SHELVINGS_LIST = 'SET_SHELVINGS_LIST';
export const SET_FILTERED_SHELVINGS_LIST = 'SET_FILTERED_SHELVINGS_LIST';
export const SET_SHELVINGS_SEARCH_RESULT = 'SET_SHELVINGS_SEARCH_RESULT';

export const SET_SELECTED_SHELF = 'SET_SELECTED_SHELF';

export const SET_MODAL_CONFIG = 'SET_MODAL_CONFIG';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const API_CALL = 'API_CALL';

export const TOAST_ERROR = 'TOAST_ERROR';
export const TOAST_SUCCESS = 'TOAST_SUCCESS';

export const SET_START_REQUEST = 'SET_START_REQUEST';
export const SET_STOP_REQUEST = 'SET_STOP_REQUEST';

export const SET_UNLOCK_RELOCATION_ROWS = 'SET_UNLOCK_RELOCATION_ROWS';
export const SET_CLOSING_RESERVATIONS_ROWS = 'SET_CLOSING_RESERVATIONS_ROWS';
export const SET_HANDLE_PICKING_BATCHES = 'SET_HANDLE_PICKING_BATCHES';

export const SET_CHECK_QUANTITY_ROWS = 'SET_CHECK_QUANTITY_ROWS';
export const UPDATE_CHECK_QUANTITY_ROWS = 'UPDATE_CHECK_QUANTITY_ROWS';

export const SET_CHECK_INVENTORIES_LIST = 'SET_CHECK_INVENTORIES_LIST';
export const SET_CHECK_INVENTORY_SHELVINGS_LIST =
  'SET_CHECK_INVENTORY_SHELVINGS_LIST';
export const SET_SELECTED_CHECK_INVENTORY = 'SET_SELECTED_CHECK_INVENTORY';
export const SET_SELECTED_CHECK_INVENTORY_SHELF =
  'SET_SELECTED_CHECK_INVENTORY_SHELF';
export const SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS =
  'SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS';
export const SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED =
  'SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED';
export const SET_SINGLE_LOCATIONS_CHECK = 'SET_SINGLE_LOCATIONS_CHECK';
export const ADD_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED =
  'ADD_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED';

export const SET_PREORDER_PRODUCTS_LIST = 'SET_PREORDER_PRODUCTS_LIST';
export const RESET_PREORDER_PRODUCTS_LIST = 'RESET_PREORDER_PRODUCTS_LIST';
export const SET_SELECTED_PREORDER_PRODUCT = 'SET_SELECTED_PREORDER_PRODUCT';

//* CoreTable
export const SET_HIDDEN_COLUMNS = 'SET_HIDDEN_COLUMNS';

export const SET_NEW_CUSTOMER = 'SET_NEW_CUSTOMER';
